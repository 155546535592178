<template>
  <div class="signError-wrap">
    <div class="header">
      <div class="title">您无权限查看此文件</div>
      <div class="img-wrap">
        <img src="@/assets/imgs/authenticationError.png" alt />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        creditMsg: this.$store.state.creditMsg,
      }
    },
    methods: {
      goUrl(url) {
        this.$router.push(url)
      },
    },
  }
</script>
<style lang="less" scoped>
  .signError-wrap {
    background: rgba(248, 249, 251, 1);
    height: 100%;
    .header {
      padding-top: 57px;
      padding-bottom: 32px;
      margin-bottom: 8px;
      background-color: #fff;
      .img-wrap {
        width: 120px;
        height: 120px;
        margin: 16px auto;
        img {
          width: 120px;
          height: 120px;
        }
      }
      .title {
        height: 24px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(17, 26, 52, 1);
        line-height: 24px;
        text-align: center;
      }
    }
    .checkMsg {
      padding: 0 16px;
      margin-bottom: 24px;
      background-color: #fff;
      .checkMsg-title {
        height: 44px;
        border-bottom: 1px solid rgba(245, 245, 245, 1);
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(60, 64, 67, 1);
        line-height: 44px;
      }
      .person-list {
        padding: 4px 0;
        .person-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 32px;
          .label {
            height: 24px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(103, 114, 131, 1);
            line-height: 24px;
          }
          .text {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(17, 26, 52, 1);
            line-height: 24px;
          }
        }
      }
    }
    .btn1 {
      width: 327px;
      height: 50px;
      background: rgba(22, 118, 255, 1);
      box-shadow: 0px 6px 20px 0px rgba(78, 128, 245, 0.16);
      border-radius: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 50px;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    .btn {
      width: 327px;
      height: 50px;
      background: rgba(255, 255, 255, 1);
      border-radius: 25px;
      border: 1px solid rgba(22, 118, 255, 1);
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(22, 118, 255, 1);
      line-height: 50px;
      text-align: center;
      margin: 0 auto;
    }
  }
</style>
